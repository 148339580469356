import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
  } from "@angular/core";
  import { AdminService } from "src/app/admin.service";
  import { Router, ActivatedRoute } from "@angular/router";
  import { CommentsDialogComponent } from "../comments-dialog/comments-dialog.component";
  import { MatDialog, MatSnackBar } from "@angular/material";
  import { OffersDialogComponent } from "../offers-dialog/offers-dialog.component";
  import { HttpErrorResponse } from "@angular/common/http";
  import { ImgPreviewComponent } from "../img-preview/img-preview.component";
  import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
  import { HireProvidersComponent } from "../hire-providers/hire-providers.component";

  @Component({
    selector: "app-task-details",
    templateUrl: "./task-details.component.html",
    styleUrls: ["./task-details.component.css"],
  })
  export class TaskDetailsComponent implements OnInit, OnChanges, OnDestroy {
    rawData: any = null;
    id: string = '';
    taskUserInfo: any = null;
    image: string = '';
    date: any = null;
    routeSub: any;
    txnValue: any = null;
    one: boolean = true;
    public baseUrl: string = '';
    comments: Array<any> = [];
    offers: Array<any> = [];
    assigned: Array<any> = [];
    couponObj: any = null;
    isUserDetails: boolean = false;
    customerID: string = "";
    @Input() childID: string = '';
    @Output() closeEvent = new EventEmitter();
    isFromAllTask: boolean = false;
    attachments: Array<any> = [];
    couponAmount: string = "0";
    amount: number = 0;
    userCoupons: any = [];
    userProfilePic: string = '';
    providerProfilePics: {[key: string]: string} = {};
    constructor(
      private adminService: AdminService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private dialog: MatDialog,
      private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
      setTimeout(() => {
        this.onLoad();
      });
    }
    onLoad() {
      this.baseUrl = this.adminService.baseUrl;
      this.routeSub = this.activatedRoute.params.subscribe((params) => {
        if (params && params["id"]) {
          this.id = params["id"];
          this.getTaskDetails();
        }
      });
    }
    ngOnChanges(changes: SimpleChanges) {
      if (changes['childID'] && changes['childID'].currentValue) {
        setTimeout(() => {
          this.isFromAllTask = true;
          this.id = this.childID;
          this.getTaskDetails();
        });
      }
    }
    closeDetails() {
      this.isFromAllTask = false;
      this.closeEvent.emit(true);
    }
    getColors(status) {
      switch (status) {
        case "Open":
          return "#09A804";
        case "Assigned":
          return "#FF870E";
        case "Completed":
          return "#Fa0e0e";
      }
    }
    showGallery() {
      let data = {
        isFromGallery: false,
        images: this.attachments,
      };
      let dialogRef = this.dialog.open(ImgPreviewComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: false,
        width: "40rem",
        data: data,
      });
    }
    getTaskDetails() {
      let obj = {
        userID: this.id,
      };
      
      setTimeout(() => {
        this.adminService.showLoader.next(true);
      });

      this.adminService.getMyTasks(obj).subscribe(
        (posRes) => {
          setTimeout(() => {
            this.adminService.showLoader.next(false);
          });
          
          if (posRes.response == 3 && posRes.jobsData && posRes.jobsData[0]) {
            this.rawData = posRes.jobsData[0];
            
            // Handle both old and new data structures for user info
            if (this.rawData) {
              // For new data structure
              if (this.rawData.creatorDetails) {
                this.taskUserInfo = {
                  firstName: this.rawData.creatorDetails.firstName || '',
                  lastName: this.rawData.creatorDetails.lastName || '',
                  profilePic: this.rawData.creatorDetails.profilePic || '',
                  phoneNumber: this.rawData.creatorDetails.phoneNumber || '',
                  userID: this.rawData.creatorDetails.userID || ''
                };
                
                if (this.rawData.creatorDetails.userID) {
                  this.fetchUserProfilePic(this.rawData.creatorDetails.userID);
                }
              } 
              // For old data structure
              else if (this.rawData.userID) {
                // Fetch user details for old data structure
                this.adminService.getUserDetails({ userID: this.rawData.userID }).subscribe(
                  (userRes) => {
                    if (userRes.response == 3 && userRes.customerInfo && userRes.customerInfo[0]) {
                      const userInfo = userRes.customerInfo[0];
                      this.taskUserInfo = {
                        firstName: userInfo.firstName || '',
                        lastName: userInfo.lastName || '',
                        profilePic: userInfo.profilePic || '',
                        phoneNumber: userInfo.phoneNumber || '',
                        userID: this.rawData.userID
                      };
                      this.fetchUserProfilePic(this.rawData.userID);
                    }
                  },
                  (err) => {
                    console.warn("Error fetching old user details:", err);
                  }
                );
              }

              // Initialize other data with null checks
              this.comments = this.rawData.comments || [];
              this.offers = this.rawData.offers || [];
              this.assigned = this.offers.filter((val) => {
                return val.isTaskerHired && !val.isTaskerWithDraw;
              });

              // Handle attachments safely
              this.attachments = [];
              if (this.rawData.attachments && Array.isArray(this.rawData.attachments)) {
                this.rawData.attachments.forEach((val) => {
                  if (val) {
                    this.attachments.push(this.baseUrl + val);
                  }
                });
              }

              // Fetch provider profile pictures
              if (this.offers && Array.isArray(this.offers)) {
                this.offers.forEach(offer => {
                  if (offer && offer.offeredUserID) {
                    this.fetchProviderProfilePic(offer.offeredUserID);
                  }
                });
              }

              // Handle dates safely
              if (this.rawData.postedDate) {
                // Handle both string and number formats
                const dateValue = typeof this.rawData.postedDate === 'string' ? 
                  parseInt(this.rawData.postedDate) : this.rawData.postedDate;
                this.date = new Date(dateValue);
              }

              // Handle task dates safely
              if (this.rawData.taskDate && Array.isArray(this.rawData.taskDate)) {
                this.rawData.taskDate = this.rawData.taskDate.map(date => {
                  const dateValue = typeof date === 'string' ? parseInt(date) : date;
                  return dateValue;
                });
              }

              // Handle budget calculation for both structures
              if (this.rawData.budget) {
                const budget = this.rawData.budget;
                if (budget.budgetType) {
                  if (budget.budgetType.HourlyRate || budget.budgetType.Total === false) {
                    const hours = parseInt(budget.Hours) || 0;
                    const rate = parseFloat(budget.pricePerHour) || 0;
                    budget.budget = hours * rate;
                  }
                }
              }
            }
          } else {
            this.openSnackBar(posRes.message || "Error loading task details", "");
          }
        },
        (err: HttpErrorResponse) => {
          setTimeout(() => {
            this.adminService.showLoader.next(false);
          });
          console.warn("Error fetching task details:", err);
          this.openSnackBar("Error loading task details", "");
        }
      );
    }
    getUserDetails() {
      if (!this.rawData || !this.rawData.userID) {
        return;
      }

      let obj = {
        userID: this.rawData.userID,
      };
      this.adminService.getUserDetails(obj).subscribe(
        (posRes) => {
          if (posRes.response == 3 && posRes.customerInfo && posRes.customerInfo[0]) {
            this.userCoupons = posRes.customerInfo[0].coupons || [];
          }
        },
        (err: HttpErrorResponse) => {
          console.warn("Error fetching user details:", err);
        }
      );
    }
    userDetails(id) {
      setTimeout(() => {
        this.adminService.showLoader.next(true);
        this.isUserDetails = true;
        this.customerID = id;
      });
    }
    receiveMessage(event) {
      this.isUserDetails = event;
    }
    openPrivateChat(offer) {
      let obj = {
        offers: offer,
        postID: this.rawData.postID,
        readOnly: false,
      };
      let dialogRef = this.dialog.open(OffersDialogComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: true,
        data: obj,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.getTaskDetails();
        }
      });
    }
    openComments() {
      let obj = {
        offers: this.comments,
        postID: this.rawData.postID,
      };
      let dialogRef = this.dialog.open(CommentsDialogComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: true,
        data: obj,
      });
      dialogRef.afterClosed().subscribe((res) => {
        this.getTaskDetails();
      });
    }
    openOffers() {
      this.txnValue = {};
      if (this.offers.length) {
        let obj = {
          offers: this.offers,
          postID: this.rawData.postID,
          taskTitle: this.rawData.postTitle,
          coupons: this.userCoupons,
        };
        let dialogRef = this.dialog.open(HireProvidersComponent, {
          panelClass: "col-md-4",
          hasBackdrop: true,
          disableClose: true,
          data: obj,
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res !== false) {
            this.txnValue = res.txnValue;
            this.amount = 0;
            console.log("Selected Offers", res);
            let bookedTaskers = [];
            res.offers.forEach((val) => {
              let obj = {
                offeredUserID: val.offeredUserID,
                offeredUserName: val.authorName,
                budget: val.budget,
                offeredUserProfilePic: val.authorProfilePic,
                isCustomerCompletedTask: false,
                isTaskerCompletedTask: false,
                paymentStatusToProviderByAdmin: false,
                isWithDraw: false,
                ratingsToProvider: false,
                ratingsToPoster: false,
              };
              debugger;
              this.amount += val.budget;
              bookedTaskers.push(obj);
            });
            this.couponAmount = "0";
            if (res.isCouponApplied) {
              this.amount -= parseInt(res.couponObj.couponAmount);
              this.couponAmount = "-" + res.couponObj.couponAmount;
            }
            this.couponObj = res.couponObj;
            // localStorage.setItem('coupon',JSON.stringify(res.couponObj))
            // localStorage.setItem('selectedUsers', JSON.stringify(bookedTaskers));
            this.hireTaskers(bookedTaskers);
          }
        });
      } else {
        this.openSnackBar("No providers to hire..!", "");
      }
    }
    // Hire Taskers

    hireTaskers(details) {
      debugger;
      let coupon = this.couponObj;
      let PaymentID = "Pid" + new Date().getTime();
      let payLoad = {
        TxnMessage: "MAYBANK2U|YEOH HUI LENG|Transaction Successful|00|",
        PymtMethod: "DD",
        TransactionType: "SALE",
        TxnID: this.txnValue.txnID,
        HashValue2:
          "755856bfa75ec9b71dbbfa7a17c3a08a8add3ed46d39297f1e141ae2418e184c",
        OrderNumber: this.txnValue.orderNum,
        Amount: "" + this.amount.toFixed(2),
        ServiceID: "CLS",
        HashValue:
          "0b9a49f66e669a552c32e93711c7c7293a4c3c2eace2a633959ed257efbcfbee",
        paymentID: PaymentID,
        CurrencyCode: "MYR",
        TxnStatus: "0",
      };

      let bookingID = "STR" + new Date().getTime();
      let hireTaskObj = {
        describeTaskInDetails: this.rawData.describeTaskInDetails,
        taskDate: this.rawData.taskDate,
        attachments: this.rawData.attachments,
        taskTotalBudget: this.amount,
        mustHaves: this.rawData.mustHaves,
        convenientTimings: this.rawData.convenientTimings,
        userID: this.rawData.userID,
        paymentID: PaymentID,
        bookingID: bookingID,
        postID: this.rawData.postID,
        customerProfilePic: this.rawData.userInfo.profilePic,
        location: this.rawData.location,
        loc: this.rawData.loc,
        serviceCategory: this.rawData.category.categoryName,
        taskTitle: this.rawData.postTitle,
        customerName: this.rawData.userInfo.firstName,
        paymentStatus: "Completed",
        bookedTaskers: details,
        couponCode: coupon.couponCode,
        couponAmount: coupon.couponAmount,
        couponDiscount: this.couponAmount,
        paymentData: payLoad,
      };

      let token = sessionStorage.getItem("token");

      this.adminService.hireProviders(hireTaskObj, token).subscribe(
        (posRes) => {
          if (posRes.response == 3) {
            this.getTaskDetails();
            this.openSnackBar(posRes.message, "");
          } else {
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.warn("CSE", err.error);
          } else {
            console.warn("SSE", err.message);
          }
        }
      );
    }
    //message alerts showing
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 3000,
      });
    }
    deleteOffers(offer, i) {
      let obj = {
        postID: this.rawData.postID,
        offeredUserID: offer.offeredUserID,
      };
      let message = `Do you want to delete this Offer ?`;
      let dialogRef = this.dialog.open(DeleteDialogComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: true,
        data: message,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          let token = sessionStorage.getItem("token");
          this.adminService.deleteOffer(obj, token).subscribe(
            (posRes) => {
              if (posRes.response == 3) {
                this.openSnackBar(posRes.message, "");
                this.offers.splice(i, 1);
              } else {
                this.openSnackBar(posRes.message, "");
              }
            },
            (err: HttpErrorResponse) => {
              this.openSnackBar(err.message, "");
              if (err.error instanceof Error) {
                console.warn("Client Error", err.error);
              } else {
                console.warn("Server Error", err.error);
              }
            }
          );
        }
      });
    }
    fetchUserProfilePic(userID: string) {
      const token = sessionStorage.getItem("token");
      this.adminService.getUserProfilePic(userID, token).subscribe(
        (response) => {
          if (response.response === 3 && response.customerInfo && response.customerInfo[0]) {
            const profilePic = response.customerInfo[0].profilePic;
            // Only update if it's a valid URL
            if (profilePic && (profilePic.startsWith('https') || profilePic.startsWith('sgp1'))) {
              this.userProfilePic = profilePic;
            } else {
              this.userProfilePic = '../../../assets/default-profile.png';
            }
          }
        },
        (err: HttpErrorResponse) => {
          console.warn("Error fetching user profile picture:", err);
          this.userProfilePic = '../../../assets/default-profile.png';
        }
      );
    }
    fetchProviderProfilePic(providerID: string) {
      const token = sessionStorage.getItem("token");
      this.adminService.getUserProfilePic(providerID, token).subscribe(
        (response) => {
          if (response.response === 3 && response.customerInfo && response.customerInfo[0]) {
            const profilePic = response.customerInfo[0].profilePic;
            // Only update if it's a valid URL
            if (profilePic && (profilePic.startsWith('https') || profilePic.startsWith('sgp1'))) {
              this.providerProfilePics[providerID] = profilePic;
            } else {
              this.providerProfilePics[providerID] = '../../../assets/default-profile.png';
            }
          }
        },
        (err: HttpErrorResponse) => {
          console.warn("Error fetching provider profile picture:", err);
          this.providerProfilePics[providerID] = '../../../assets/default-profile.png';
        }
      );
    }
    ngOnDestroy() {
      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    }
  }
