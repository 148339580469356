import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { AdminService } from "src/app/admin.service";
import { AccountVerificationModalComponent } from "../account-verification-modal/account-verification-modal.component";
import { ImgPreviewComponent } from "../img-preview/img-preview.component";
import { ReviewModalComponent } from "../review-modal/review-modal.component";
import { ShowOfferedJobsComponent } from "../show-offered-jobs/show-offered-jobs.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

const COUNTRY_CODES = {
  '+60': 'MY',  // Malaysia
  '+63': 'PH',  // Philippines
  '+65': 'SG',  // Singapore
  '+62': 'ID',  // Indonesia
  '+66': 'TH',  // Thailand
  '+84': 'VN',  // Vietnam
} as const;

@Component({
  selector: "app-customer-details",
  templateUrl: "./customer-details.component.html",
  styleUrls: ["./customer-details.component.css"],
})
export class CustomerDetailsComponent implements OnInit, OnChanges {
  asPoster: boolean = false;
  asTasker: boolean = false;
  image: string =
    "https://tasker.vyte.sh//images/Customers/oPJpQ1600825844027JPEG_20200923_095041_1007186395530940084.jpg";
  UserDetails: any;
  id: any;
  routeSub: any;
  baseUrl: string = "";
  gallery: Array<any> = [];
  skills: any;
  bankDetails: any;
  accountData: any = {};
  accountVerificationForm: FormGroup;
  posterReviews: any;
  isAccountVerified: boolean = false;
  isAccountRejected: boolean = false;
  providerReviews: any;
  isSelfieUploaded: boolean = false;
  isIDUploaded: boolean = false;
  totalReviews: number = 0;
  isFromAllCustomers: boolean = false;
  userReviews: any = {
    TaskCompletedCount: 0,
    completedPercentage: "0",
  };
  isUpdatePhone: boolean = false;
  isUserBlocked: boolean = false;
  galleryImages: Array<any> = [];
  paymentReceipt: any = "../../../assets/Add_image.png";
  isPaymentReceiptUploaded: boolean = false;
  doUpdatePaymentReceipt: boolean = false;
  isUpdateEmail: boolean = false;
  emergencyContact: any = {
    name: '',
    phoneNumber: ''
  };
  isAddingContact: boolean = false;
  isEditingProfile: boolean = false;
  editedProfile: any = {
    phoneNumber: '',
    email: '',
    emergencyContacts: []
  };
  isEditingVerification: boolean = false;
  editedVerification: any = {
    Nationality: '',
    IDType: '',
    IDPhoto: '',
    selfiePhoto: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: ''
  };
  idPhotoFile: File | null = null;
  selfiePhotoFile: File | null = null;
  nationalityOptions: string[] = ['MALAYSIA', 'FOREIGNER'];
  idTypeOptions: string[] = ['MYKAD', "DRIVER'S LICENSE", 'PASSPORT'];
  maxDate: Date = new Date();
  userProfilePic: string = '';

  @Input() childID: string;
  @Output() closeEvent = new EventEmitter();
  @Output() refreshList = new EventEmitter<boolean>();
  constructor(
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }

  ngOnInit() {
    this.onLoad();

    this.accountVerificationForm = this.fb.group({
      paymentReceipt: ["", Validators.required],
      verificationData: this.fb.group({
        userID: [this.childID]
      })
    });

    // Ensure options are in uppercase to match data
    this.nationalityOptions = ['MALAYSIA', 'FOREIGNER'];
    this.idTypeOptions = ['MYKAD', "DRIVER'S LICENSE", 'PASSPORT'];
  }
  ngOnChanges(changes: SimpleChanges) {
    this.id = this.childID;
    this.isFromAllCustomers = true;
    this.fetchData();
  }
  onLoad() {
    this.baseUrl = this.adminService.baseUrl;
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      //log the entire params object
      if (params && params["id"]) {
        this.id = params["id"];
        this.fetchData();
      }
    });
  }
  openGallery() {
    let data = {
      isFromGallery: true,
      images: this.gallery,
    };
    let dialogRef = this.dialog.open(ImgPreviewComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: false,
      width: "40rem",
      data: data,
    });
  }
  closeDetails() {
    this.isFromAllCustomers = false;
    this.closeEvent.emit(false);
  }

  blockOrUnBlock() {
    let obj = {
      customerID: this.UserDetails.userID,
      isBlocked: !this.isUserBlocked,
    };
    this.adminService.showLoader.next(true);
    let token = sessionStorage.getItem("token");
    this.adminService.blockUnBlock(obj, token).subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.adminService.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          this.isUserBlocked = !this.isUserBlocked;
          // if(this.isUserBlocked){
          //   this.fetchUserOfferedJobs();
          // }
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Side Error", err.error);
        } else {
          console.warn("Server Side Error", err.error);
        }
      }
    );
  }
  fetchUserOfferedJobs() {
    let token = sessionStorage.getItem("token");
    let obj = {
      userID: this.UserDetails.userID,
    };
    this.adminService.getMyOfferedTasks(obj, token).subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          let data = {
            userID: this.UserDetails.userID,
            jobsData: posRes.jobsData,
          };
          this.showOfferedJobs(data);
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        this.openSnackBar(err.message, "");
        if (err.error instanceof Error) {
          console.warn("Client Side Error", err.error);
        } else {
          console.warn("Server Side Error", err.error);
        }
      }
    );
  }
  showOfferedJobs(data) {
    let dialogRef = this.dialog.open(ShowOfferedJobsComponent, {
      panelClass: "col-md-4",
      disableClose: true,
      hasBackdrop: true,
      data: data,
    });
  }
  fetchData() {
    this.adminService.showLoader.next(true);
    let id = { userID: this.id };
    let token = sessionStorage.getItem("token");
    this.adminService.fetchUserDetails(id, token).subscribe(
      (posRes) => {
        console.log("PosREs ", posRes);

        if (posRes.response == 3) {
          this.UserDetails = posRes.userInfo[0];
          console.log(this.UserDetails);
          this.isUserBlocked = this.UserDetails.isUserBlocked;
          this.isAccountVerified = this.UserDetails.accountVerificationStatus === "Verified";
          this.asTasker = this.UserDetails.completeTask;
          this.asPoster = this.UserDetails.postTask;
          this.bankDetails = this.UserDetails.BankAccountDetailes;
          this.accountData = this.UserDetails.accountData;
          this.image = this.UserDetails.profilePic;
          this.providerReviews = posRes.asAProvider;
          this.posterReviews = posRes.asAPoster;
          if (this.UserDetails.gallery.length != 0) {
            this.UserDetails.gallery.forEach((val, index) => {
              if (val.substring(val.lastIndexOf(".") + 1) !== "mp4") {
                this.gallery.push(val);
              }
            });
          }
          this.skills = this.UserDetails.Settings[0].skills;
          let event = {
            value: "tasker",
          };
          this.changeView(event);
          this.fetchUserProfilePic(this.UserDetails.userID);
        } else {
          this.adminService.showLoader.next(false);
          alert(posRes.message);
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        if (err.error instanceof Error) {
          console.log("CSE", err.message);
        } else {
          console.log("SSE", err.message);
        }
      }
    );
  }
  editNumber() {
    this.isUpdatePhone = true;
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  editPhoneNumber() {
    let formData = new FormData();
    formData.append("userID", this.id);
    formData.append("phoneNumber", this.UserDetails.phoneNumber);
    let token = sessionStorage.getItem("token");
    this.adminService.updateMobileNumber(formData, token).subscribe(
      (posRes) => {
        this.adminService.showLoader.next(false);
        this.isUpdatePhone = false;

        if (posRes.response == 3) {
          this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.adminService.showLoader.next(false);
          this.openSnackBar(err.message, "");
          console.log("CSE", err.message);
        } else {
          console.log("SSE", err.message);
        }
      }
    );
  }
  showGallery() {
    if (this.galleryImages && this.galleryImages.length > 0) {
      let data = {
        isFromGallery: false,
        images: this.galleryImages,
        currentIndex: 0  // Add initial index
      };
      
      let dialogRef = this.dialog.open(ImgPreviewComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: false,
        width: "40rem",
        data: data
      });
    } else {
      this.openSnackBar('No images to display', '');
    }
  }
  showIdProof(img1: string, img2: string) {
    // Clear the gallery array first
    this.galleryImages = [];
    
    // Only add images that exist and are valid URLs
    if (img1 && typeof img1 === 'string' && img1.length > 0) {
      // Add the full URL if it's not already a full URL
      const img1Url = img1.startsWith('http') ? img1 : this.baseUrl + img1;
      this.galleryImages.push(img1Url);
    }
    
    if (img2 && typeof img2 === 'string' && img2.length > 0) {
      // Add the full URL if it's not already a full URL
      const img2Url = img2.startsWith('http') ? img2 : this.baseUrl + img2;
      this.galleryImages.push(img2Url);
    }

    console.log('Gallery Images for preview:', this.galleryImages);

    if (this.galleryImages.length > 0) {
      let data = {
        isFromGallery: false,
        images: this.galleryImages,
        currentIndex: 0  // Add initial index
      };
      
      let dialogRef = this.dialog.open(ImgPreviewComponent, {
        panelClass: "col-md-4",
        hasBackdrop: true,
        disableClose: false,
        width: "40rem",
        data: data
      });
    } else {
      this.openSnackBar('No valid images to display', '');
    }
  }
  showPaymentProof(img) {
    this.galleryImages = [];
    this.galleryImages.push(this.baseUrl + img);
    this.showGallery();
  }
  dpPreview(img) {
    this.galleryImages = [];
    this.galleryImages.push(this.userProfilePic || '../../../assets/default-profile.png');
    this.showGallery();
  }
  viewReviews() {
    let obj = {
      provider: this.providerReviews,
      poster: this.posterReviews,
    };
    let dialogRef = this.dialog.open(ReviewModalComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
      data: obj,
    });
  }
  verify() {
    this.adminService.showLoader.next(true);
    let obj = {
      userID: this.UserDetails.userID,
      isVerified: "Verified",
      reason: "",
    };
    let token = sessionStorage.getItem("token");
    this.adminService.verifyCustomer(obj, token).subscribe(
      (posRes) => {
        this.adminService.showLoader.next(false);
        if (posRes.response == 3) {
          let obj = {
            type: "verified",
          };
          let dialogRef = this.dialog.open(AccountVerificationModalComponent, {
            panelClass: "col-md-4",
            hasBackdrop: true,
            disableClose: true,
            data: obj,
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
              this.fetchData();
              this.updateCustomerDetails();
            }
          });
        } else {
          alert(posRes.message);
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      }
    );
  }

  reject() {
    let obj = {
      type: "unVerified",
    };
    let dialogRef = this.dialog.open(AccountVerificationModalComponent, {
      panelClass: "col-md-3",
      hasBackdrop: true,
      disableClose: true,
      data: obj,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.reason) {
        this.adminService.showLoader.next(true);
        let obj = {
          userID: this.UserDetails.userID,
          isVerified: "Rejected",
          reason: res.reason,
        };
        let token = sessionStorage.getItem("token");
        this.adminService.verifyCustomer(obj, token).subscribe(
          (posRes) => {
            this.adminService.showLoader.next(false);
            if (posRes.response == 3) {
              this.fetchData();
              this.updateCustomerDetails();
            } else {
              alert(posRes.message);
            }
          },
          (err: HttpErrorResponse) => {
            this.adminService.showLoader.next(false);
            if (err.error instanceof Error) {
              console.warn("Client Side Error", err.error);
            } else {
              console.warn("Server side Error", err.error);
            }
          }
        );
      }
    });
  }
  changeView(event) {
    if (event.value == "poster") {
      this.asPoster = true;
      if (this.posterReviews && this.posterReviews.rating5) {
        this.userReviews = this.posterReviews.posterTaskCompletedDetails;
        let one = this.posterReviews.rating1.length;
        let two = this.posterReviews.rating2.length;
        let three = this.posterReviews.rating3.length;
        let four = this.posterReviews.rating4.length;
        let five = this.posterReviews.rating5.length;
        this.totalReviews = one + two + three + four + five;
      } else {
        this.totalReviews = 0;
        this.userReviews = {
          TaskCompletedCount: 0,
          completedPercentage: "",
        };
      }
    } else {
      this.asPoster = false;
      if (this.providerReviews && this.providerReviews.rating5) {
        setTimeout(() => {
          this.userReviews = this.providerReviews.providerTaskCompletedDetails;
        }, 100);
        let one = this.providerReviews.rating1.length;
        let two = this.providerReviews.rating2.length;
        let three = this.providerReviews.rating3.length;
        let four = this.providerReviews.rating4.length;
        let five = this.providerReviews.rating5.length;
        this.totalReviews = one + two + three + four + five;
      } else {
        this.totalReviews = 0;
        this.userReviews = {
          TaskCompletedCount: 0,
          completedPercentage: "",
        };
      }
    }
    this.adminService.showLoader.next(false);
  }

  takePaymentReceipt(event) {
    let file = event.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.paymentReceipt = reader.result;
      this.isPaymentReceiptUploaded = true;
      this.accountVerificationForm.get('paymentReceipt').setValue(file)
    }
  }

  updatePaymentReceipt() {
    if (this.accountVerificationForm.valid) {
      this.adminService.showLoader.next(true);
      let payLoad = { ...this.accountVerificationForm.value }
      let formData = new FormData();
      formData.append("verificationData", JSON.stringify(payLoad.verificationData))
      formData.append("paymentReceipt", payLoad.paymentReceipt);
      let token = sessionStorage.getItem('token');
      this.adminService.updatePaymentReceipt(formData, token).subscribe((posRes) => {
        this.adminService.showLoader.next(false);
        if (posRes.response == 3) {
          this.openSnackBar(posRes.message, "")

          this.fetchData();
          this.doUpdatePaymentReceipt = !this.doUpdatePaymentReceipt;
        } else {
          this.openSnackBar(posRes.message, "")
        }
      }, (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Error", err.message);
        } else {
          console.warn("Error", err.message);
        }
      })
    } else {
      if (this.accountVerificationForm.get('paymentReceipt').invalid) {
        this.openSnackBar("Upload your Payment Receipt..", "")
      }
    }
  }

  editEmail() {
    this.isUpdateEmail = true;
  }

  toggleEditProfile() {
    if (!this.isEditingProfile) {
      // Start editing - copy current values
      this.editedProfile = {
        phoneNumber: this.UserDetails.phoneNumber,
        email: this.UserDetails.userID,
        emergencyContacts: [...this.UserDetails.emergencyContacts.map(contact => ({
          contact: {
            phoneNumber: contact.contact.phoneNumber,
            countryCode: contact.contact.countryCode || this.getCountryCodeFromPhone(contact.contact.phoneNumber)
          },
          NameoftheContactPerson: contact.NameoftheContactPerson
        }))]
      };
    }
    this.isEditingProfile = !this.isEditingProfile;
  }

  addNewEmergencyContact() {
    this.editedProfile.emergencyContacts.push({
      contact: {
        phoneNumber: '',
        countryCode: 'MY'  // Default to Malaysia
      },
      NameoftheContactPerson: ''
    });
  }

  removeEmergencyContact(index: number) {
    this.editedProfile.emergencyContacts.splice(index, 1);
  }

  saveProfile() {
    // Format phone numbers before saving - store only the raw numbers
    const formattedContacts = this.editedProfile.emergencyContacts.map(contact => {
      let phoneNumber = contact.contact.phoneNumber;
      
      // Remove any existing country code prefix
      Object.keys(COUNTRY_CODES).forEach(prefix => {
        if (phoneNumber.startsWith(prefix)) {
          phoneNumber = phoneNumber.substring(prefix.length);
        }
      });
      
      // Remove any leading + if present
      phoneNumber = phoneNumber.replace(/^\+/, '');
      
      return {
        ...contact,
        contact: {
          phoneNumber: phoneNumber, // Store clean number without country code
          countryCode: contact.contact.countryCode
        }
      };
    });

    let formData = new FormData();
    formData.append("userID", this.id);
    formData.append("phoneNumber", this.editedProfile.phoneNumber);
    formData.append("email", this.editedProfile.email);
    formData.append("emergencyContacts", JSON.stringify(formattedContacts));
    
    let token = sessionStorage.getItem("token");
    this.adminService.updateProfile(formData, token).subscribe(
      (posRes) => {
        this.adminService.showLoader.next(false);
        if (posRes.response == 3) {
          this.openSnackBar(posRes.message, "");
          
          // Check if email was changed
          const isEmailChanged = this.editedProfile.email !== this.UserDetails.userID;
          
          // Create updated customer object with new data
          const updatedCustomer = {
            ...this.UserDetails,
            userID: this.editedProfile.email,  // Update userID with new email
            phoneNumber: this.editedProfile.phoneNumber,
            emergencyContacts: formattedContacts
          };
          
          // Update UserDetails with the new data
          this.UserDetails = updatedCustomer;
          
          // Emit the update to refresh the list
          this.adminService.emitCustomerUpdate(updatedCustomer);
          
          if (isEmailChanged) {
            // Emit event to parent to refresh list
            this.refreshList.emit(true);
            // Close the details view
            this.closeDetails();
          } else {
            // Just refresh the current view
            this.fetchData();
            this.isEditingProfile = false;
          }
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        this.openSnackBar(err.message, "");
        console.warn("Error", err.message);
      }
    );
  }

  getCountryCodeFromPhone(phoneNumber: string): string {
    for (const [prefix, code] of Object.entries(COUNTRY_CODES)) {
      if (phoneNumber.startsWith(prefix)) {
        return code;
      }
    }
    return 'MY'; // Default to Malaysia if no match found
  }

  getPhonePrefix(countryCode: string): string {
    for (const [prefix, code] of Object.entries(COUNTRY_CODES)) {
      if (code === countryCode) {
        return prefix;
      }
    }
    return '+60'; // Default to Malaysia prefix
  }

  formatPhoneNumber(contact: any) {
    // Don't modify the phone number, just ensure the country code is set
    return {
      ...contact,
      contact: {
        phoneNumber: contact.contact.phoneNumber.replace(/^\+/, ''), // Remove any leading + if present
        countryCode: contact.contact.countryCode
      }
    };
  }

  getDisplayPhoneNumber(contact: any): string {
    const prefix = this.getPhonePrefix(contact.contact.countryCode || this.getCountryCodeFromPhone(contact.contact.phoneNumber));
    let phoneNumber = contact.contact.phoneNumber;
    
    // Remove any existing country code prefix if present
    Object.keys(COUNTRY_CODES).forEach(prefix => {
      if (phoneNumber.startsWith(prefix)) {
        phoneNumber = phoneNumber.substring(prefix.length);
      }
    });
    
    // Remove any leading + if present
    phoneNumber = phoneNumber.replace(/^\+/, '');
    
    return `${prefix} ${phoneNumber}`;
  }

  updateCustomerDetails() {
    // Create the updated customer object with the current user details
    const updatedCustomer = {
      userID: this.UserDetails.userID,
      firstName: this.UserDetails.firstName,
      lastName: this.UserDetails.lastName,
      profilePic: this.UserDetails.profilePic,
      phoneNumber: this.UserDetails.phoneNumber,
      accountVerificationStatus: this.UserDetails.accountVerificationStatus,
      address1: this.UserDetails.address1,
      register_time: this.UserDetails.register_time,
      verificationData: this.UserDetails.verificationData
    };
    
    // After successful update
    this.adminService.emitCustomerUpdate(updatedCustomer);
  }

  toggleEditVerification() {
    if (!this.isEditingVerification) {
      console.log('Current accountData:', this.accountData);
      
      // Initialize editedVerification with existing data or defaults
      this.editedVerification = {
        // Use existing data from accountData if available, otherwise use UserDetails
        Nationality: this.accountData && this.accountData.Nationality ? this.accountData.Nationality.toUpperCase() : '',
        IDType: this.accountData && this.accountData.IDType ? this.accountData.IDType.toUpperCase() : '',
        IDPhoto: this.accountData && this.accountData.IDPhoto ? this.accountData.IDPhoto : '',
        selfiePhoto: this.accountData && this.accountData.selfiePhoto ? this.accountData.selfiePhoto : '',
        firstName: this.UserDetails && this.UserDetails.firstName ? this.UserDetails.firstName : '',
        lastName: this.UserDetails && this.UserDetails.lastName ? this.UserDetails.lastName : '',
        email: this.UserDetails && this.UserDetails.userID ? this.UserDetails.userID : '',
        phoneNumber: this.UserDetails && this.UserDetails.phoneNumber ? this.UserDetails.phoneNumber : '',
        dob: this.accountData && this.accountData.DOB ? 
             new Date(this.accountData.DOB) : 
             this.UserDetails && this.UserDetails.dob ? 
             new Date(this.UserDetails.dob) : 
             null
      };

      console.log('Initialized editedVerification:', this.editedVerification);
      console.log('Available nationality options:', this.nationalityOptions);
      console.log('Available ID type options:', this.idTypeOptions);
    }
    this.isEditingVerification = !this.isEditingVerification;
  }

  handleIdPhotoUpload(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.idPhotoFile = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.editedVerification.IDPhoto = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  handleSelfiePhotoUpload(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selfiePhotoFile = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.editedVerification.selfiePhoto = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  saveVerificationData() {
    console.log('Current User Details:', this.UserDetails);
    console.log('Current Account Data:', this.UserDetails.accountData);
    console.log('Edited Verification Data:', this.editedVerification);

    const formData = new FormData();
    formData.append('userID', this.UserDetails.userID);

    // Create verification data matching the structure
    const verificationData = {
      ...this.UserDetails.accountData,
      firstName: this.editedVerification.firstName,
      lastName: this.editedVerification.lastName,
      Nationality: this.editedVerification.Nationality,
      IDType: this.editedVerification.IDType,
      phoneNumber: this.editedVerification.phoneNumber,
      Email: this.editedVerification.email,
      DOB: this.editedVerification.dob ? 
           this.formatDate(this.editedVerification.dob) : 
           null,
      // Preserve existing data if it exists, otherwise initialize with defaults
      _id: this.UserDetails.accountData ? this.UserDetails.accountData._id : undefined,
      timestamp: this.UserDetails.accountData ? this.UserDetails.accountData.timestamp : new Date().toISOString(),
      isVerified: this.UserDetails.accountData ? this.UserDetails.accountData.isVerified : false,
      reason: this.UserDetails.accountData ? this.UserDetails.accountData.reason : '',
      paymentStatus: this.UserDetails.accountData ? this.UserDetails.accountData.paymentStatus : '',
      paymentDateTime: this.UserDetails.accountData ? this.UserDetails.accountData.paymentDateTime : '',
      ghlTransID: this.UserDetails.accountData ? this.UserDetails.accountData.ghlTransID : '',
      paymentID: this.UserDetails.accountData ? this.UserDetails.accountData.paymentID : '',
      orderNumber: this.UserDetails.accountData ? this.UserDetails.accountData.orderNumber : '',
      paymentAmount: this.UserDetails.accountData ? this.UserDetails.accountData.paymentAmount : '',
      paymentReceipt: this.UserDetails.accountData ? this.UserDetails.accountData.paymentReceipt : '',
      __v: this.UserDetails.accountData ? this.UserDetails.accountData.__v : 0
    };

    console.log('Combined Verification Data:', verificationData);
    formData.append('verificationData', JSON.stringify(verificationData));

    if (this.idPhotoFile) {
      formData.append('IDPhoto', this.idPhotoFile);
      console.log('New ID Photo being uploaded');
    }
    if (this.selfiePhotoFile) {
      formData.append('selfiePhoto', this.selfiePhotoFile);
      console.log('New Selfie Photo being uploaded');
    }

    let token = sessionStorage.getItem('token');
    this.adminService.updateVerificationData(formData, token).subscribe(
      (posRes) => {
        console.log('Update Response:', posRes);
        this.adminService.showLoader.next(false);
        if (posRes.response == 3) {
          this.openSnackBar(posRes.message, '');
          this.fetchData();
          this.isEditingVerification = false;
          this.updateCustomerDetails();
        } else {
          this.openSnackBar(posRes.message, '');
        }
      },
      (err: HttpErrorResponse) => {
        console.error('Update Error:', err);
        this.adminService.showLoader.next(false);
        this.openSnackBar(err.message, '');
      }
    );
  }

  private formatDate(date: Date): string {
    if (!date) return '';
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  fetchUserProfilePic(userID: string) {
    const token = sessionStorage.getItem("token");
    this.adminService.getUserProfilePic(userID, token).subscribe(
      (response) => {
        if (response.response === 3) {
          this.userProfilePic = response.customerInfo[0].profilePic;
        }
      },
      (err: HttpErrorResponse) => {
        console.warn("Error fetching user profile picture:", err);
      }
    );
  }
}
